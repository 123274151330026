import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react"
import PlaceMapService, { IPlaceMap } from "../../services/PlaceMapService"
import { Alert, Button, Card, Form, InputGroup, Modal } from "react-bootstrap"
import Configs from "../../Configs"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"

interface IPlaceMapModalProps {
    placeId: number | null
    show: boolean
    title: string
    onClose: () => void
}

function PlaceMapModal(props: IPlaceMapModalProps) {
    const { hasPermission } = useAuthContext()

    const initFormData: IPlaceMap = {
        id: null,
        map: "",
        centerLat: null,
        centerLng: null,
        zoom: null,
        minZoom: null,
        maxZoom: null,
        swPanBoundaryLat: null,
        swPanBoundaryLng: null,
        nePanBoundaryLat: null,
        nePanBoundaryLng: null,
        isUsed: false,
        placeId: props.placeId
    }

    const [formData, setFormData] = useState(initFormData)

    const loadFormData = async () => {
        if (props.placeId && props.placeId > 0 && props.show) {
            const res = await PlaceMapService.get(props.placeId)

            if (res?.isSuccess) {
                setFormData(res.data)
            } else {
                console.log(res?.message)
            }
        }
    }

    useEffect(() => {
        setMap(null)
        setMessage("")
        loadFormData()
    }, [props.placeId, props.show])

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        var name = e.target.name
        var value = e.target.value

        if (["zoom", "minZoom", "maxZoom"].includes(name)) {
            setFormData({ ...formData, [name]: value ? parseInt(value) : null })
        } if (name == "isUsed") {
            setFormData({ ...formData, [name]: e.target.checked })
        } else {
            setFormData({ ...formData, [name]: value ? parseFloat(value) : null })
        }
    }

    const [message, setMessage] = useState("")
    const [processing, setProcessing] = useState<boolean>(false)
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.placeId && props.placeId > 0) {
                var frmData = new FormData()
                if (map !== null) {
                    frmData.append("Map", map)
                }
                frmData.append("centerLat", formData.centerLat ? formData.centerLat.toString() : "")
                frmData.append("centerLng", formData.centerLng ? formData.centerLng.toString() : "")
                frmData.append("Zoom", formData.zoom ? formData.zoom.toString() : "")
                frmData.append("MinZoom", formData.minZoom ? formData.minZoom.toString() : "")
                frmData.append("MaxZoom", formData.maxZoom ? formData.maxZoom.toString() : "")
                frmData.append("swPanBoundaryLat", formData.swPanBoundaryLat ? formData.swPanBoundaryLat.toString() : "")
                frmData.append("swPanBoundaryLng", formData.swPanBoundaryLng ? formData.swPanBoundaryLng.toString() : "")
                frmData.append("nePanBoundaryLat", formData.nePanBoundaryLat ? formData.nePanBoundaryLat.toString() : "")
                frmData.append("nePanBoundaryLng", formData.nePanBoundaryLng ? formData.nePanBoundaryLng.toString() : "")
                frmData.append("IsUsed", formData.isUsed ? "True" : "False")

                const res = await PlaceMapService.update(props.placeId, frmData)

                if (res.isSuccess) {
                    loadFormData()
                }

                setMessage(res?.message)
            }
            setProcessing(false)
        }
    }

    const [map, setMap] = useState<File | null>(null)
    const handleMapChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setMap(e.target.files[0])
        } else {
            setMap(null)
        }
    }

    const viewMap = (url: string | null) => {
        if (url) {
            url = url.toLowerCase()
            if (url.endsWith(".mbtiles")) {
                return <small>{Configs.API_BASE_URL}{url}</small>
            } else {
                return <img src={`${Configs.API_BASE_URL}${url}`} style={{ maxWidth: "98%", display: "inline-block", margin: "auto" }} />
            }
        }
        return <></>
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title><i className="fas fa-map"></i> Place Map <span>|</span> {props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                <Form>
                    <Form.Group controlId="mapFile" className="mb-3">
                        <Form.Label>Map</Form.Label>
                        <Card className="shadow-0">
                            {viewMap(formData.map)}
                            <Card.Body className="p-2">
                                <Form.Control type="file" accept={Configs.MAP_EXTENSION} onChange={(e: ChangeEvent<HTMLInputElement>) => handleMapChange(e)} />
                            </Card.Body>
                        </Card>
                    </Form.Group>
                    <Form.Label>Center</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Lat</InputGroup.Text>
                        <Form.Control type="number" name="centerLat" value={formData.centerLat || ""} onChange={handleFormControlChange} />
                        <InputGroup.Text>Lng</InputGroup.Text>
                        <Form.Control type="number" name="centerLng" value={formData.centerLng || ""} onChange={handleFormControlChange} />
                    </InputGroup>
                    <Form.Label>Zoom</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Default</InputGroup.Text>
                        <Form.Control type="number" min={0} name="zoom" value={formData.zoom || ""} onChange={handleFormControlChange} />
                        <InputGroup.Text>Min</InputGroup.Text>
                        <Form.Control type="number" min={0} name="minZoom" value={formData.minZoom || ""} onChange={handleFormControlChange} />
                        <InputGroup.Text>Max</InputGroup.Text>
                        <Form.Control type="number" min={0} name="maxZoom" value={formData.maxZoom || ""} onChange={handleFormControlChange} />
                    </InputGroup>
                    <Form.Label>swPanBoundary</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Lat</InputGroup.Text>
                        <Form.Control type="number" name="swPanBoundaryLat" value={formData.swPanBoundaryLat || ""} onChange={handleFormControlChange} />
                        <InputGroup.Text>Lng</InputGroup.Text>
                        <Form.Control type="number" name="swPanBoundaryLng" value={formData.swPanBoundaryLng || ""} onChange={handleFormControlChange} />
                    </InputGroup>
                    <Form.Label>nePanBoundary</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Lat</InputGroup.Text>
                        <Form.Control type="number" name="nePanBoundaryLat" value={formData.nePanBoundaryLat || ""} onChange={handleFormControlChange} />
                        <InputGroup.Text>Lng</InputGroup.Text>
                        <Form.Control type="number" name="nePanBoundaryLng" value={formData.nePanBoundaryLng || ""} onChange={handleFormControlChange} />
                    </InputGroup>
                    <Form.Check
                        type="checkbox"
                        id="isUsed"
                        name="isUsed"
                        label="Used Map"
                        checked={formData.isUsed || false}
                        onChange={handleFormControlChange}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer className="p-2">
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> Cancel</Button>
                {hasPermission(Permissions.PlaceMaps.Edit) &&
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default PlaceMapModal