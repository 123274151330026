import { Alert, Button, Card, Form, InputGroup, Modal } from "react-bootstrap"
import useAuthContext from "../../hooks/useAuthContext"
import ApiKeyService, { IApiKeyUpdateGoogleCredential } from "../../services/ApiKeyService"
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react"
import Permissions from "../../constants/Permissions"

interface IGoogleCredentialModalProps {
    providerId: number
    apiKeyId: string
    title: string
    show: boolean
    onClose: () => void
}

function GoogleCredentialModal(props: IGoogleCredentialModalProps) {
    const { hasPermission } = useAuthContext()

    const initFormData: IApiKeyUpdateGoogleCredential = {
        googleCredentialJson: "",
        googleCredentialScopes: ""
    }

    const [message, setMessage] = useState("")
    const [processing, setProcessing] = useState<boolean>(false)
    const [formData, setFormData] = useState(initFormData)

    const loadFormData = async () => {
        if (props.providerId && props.providerId > 0 && props.apiKeyId && props.show) {
            const res = await ApiKeyService.get(props.providerId, props.apiKeyId)

            if (res?.isSuccess) {
                setFormData(res.data)
            } else {
                console.log(res?.message)
            }
        }
    }

    useEffect(() => {
        setMessage("")
        loadFormData()
    }, [props.providerId, props.apiKeyId, props.show])

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        var name = e.target.name
        var value = e.target.value

        setFormData({ ...formData, [name]: value })
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.providerId && props.providerId > 0 && props.apiKeyId) {
                const res = await ApiKeyService.updateGoogleCredential(props.providerId, props.apiKeyId, formData)

                if (res.isSuccess) {
                    loadFormData()
                }

                setMessage(res?.message)
            }
            setProcessing(false)
        }
    }

    const googleCredentialJsonExamples: string = `{
  "type": "service_account",
  "project_id": "app-name",
  "private_key_id": "3041a68aa2eccb654615e47205c3425f4b68faba",
  "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAAS...oItTSdxYx8TIwHvriMc=\n-----END PRIVATE KEY-----\n",
  "client_email": "firebase-adminsdk-2xour@app-name.iam.gserviceaccount.com",
  "client_id": "105095723071239488218",
  "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  "token_uri": "https://oauth2.googleapis.com/token",
  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-2xour%40app-vsp.iam.gserviceaccount.com"
}
`

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title><i className="fab fa-google-play"></i> Google Credential <span>|</span> {props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                <Form>
                    <Form.Group className="mb-3" controlId="googleCredentialJson">
                        <Form.Label>Json</Form.Label>
                        <Form.Control as="textarea" rows={20} name="googleCredentialJson" value={formData.googleCredentialJson || ""} onChange={(e) => handleFormControlChange(e as any)} placeholder={googleCredentialJsonExamples} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="googleCredentialScopes">
                        <Form.Label>Scopes</Form.Label>
                        <Form.Control as="textarea" rows={2} name="googleCredentialScopes" value={formData.googleCredentialScopes || ""} onChange={(e) => handleFormControlChange(e as any)} placeholder="https://www.googleapis.com/auth/androidpublisher" />
                        <Form.Text>e.g.: https://www.googleapis.com/auth/androidpublisher</Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="p-2">
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> Cancel</Button>
                {hasPermission(Permissions.ApiKeys.Edit) &&
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default GoogleCredentialModal