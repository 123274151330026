import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IAreaMap {
    id: number | null
    map: string
    centerLat: number | null
    centerLng: number | null
    zoom: number | null
    minZoom: number | null
    maxZoom: number | null
    swPanBoundaryLat: number | null
    swPanBoundaryLng: number | null
    nePanBoundaryLat: number | null
    nePanBoundaryLng: number | null
    areaId: number | null
    isUsed: boolean
}

export default class AreaMapService {
    static async get(areaId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AreaMaps/${areaId}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(areaId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/AreaMaps/${areaId}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}